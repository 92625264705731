import React from 'react';

import { FooterWrap } from '../../Elements/FooterWrap/';

function Footer() {
    return (
        <FooterWrap>
            <p>Rebekah De Jong</p>
        </FooterWrap>
    );
};

export default Footer;
